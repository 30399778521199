import DomainContextParams from '@/utils/types/DomainContextParams';

export const EXHIBITOR_GENERIC_PAGE_HEADER_FRAGMENT = `
  fragment exhibitorGenericPageHeaderFragment on Exhibitor {
    id
    uid
    name
    featured
    videoPresentationS3Url
    __typename
    addresses {
      country {
        iso
        name
      }
    }
    country {
        iso
        name
      }
    logoFileResource {
      schemaCode
      path
    }
    bannerFileResource {
      schemaCode
      path
    }
    categoriesInContext(context: "${DomainContextParams.company}") {
      uid
      name
    }
    editionMappings {
      editionExhibitor {
      schemaCode
        booths {
          uid
          schemaCode
          number
          exhibitHall {
            id
            uid
            name
          }
          geozone {
            schemaCode
            id
            exhibitHall {
              id
              uid
              name
            }
          }
        }
      }
    }
    _isRecommendedForMe
    _followerCount
  }
`;
